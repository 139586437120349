import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../styles/ExecutionDetails.css';

const ExecutionDetails = () => {
  const { executionId } = useParams();
  const REACT_APP_BACKEND_URL = 
    process.env.REACT_APP_BACKEND_URL || 
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_BACKEND_URL) || 
    'http://localhost:5000';
  const [execution, setExecution] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [isStarted, setIsStarted] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchExecutionDetails(executionId);
  }, [executionId]);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');  // Get the JWT token from localStorage
    if (token) {
      // Set token in Axios default headers for all future requests
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, []);

  const fetchExecutionDetails = (id) => {
    axios.get(`${REACT_APP_BACKEND_URL}/api/executions/details/${id}`)
      .then((response) => {
        setExecution(response.data.execution);
        setTasks(response.data.tasks);
        setIsStarted(response.data.execution.status === 'running');
        setIsPaused(response.data.execution.status === 'paused');
      })
      .catch((error) => {
        console.error('Error fetching execution details:', error);
        setError('Unable to fetch execution details. Please try again later.');
      });
  };

  const handlePauseExecution = () => {
    axios.post(`${REACT_APP_BACKEND_URL}/api/tasks/pause/${executionId}`)
      .then(() => {
        setIsPaused(true);
        setIsStarted(false);
        fetchExecutionDetails(executionId);
      })
      .catch((error) => {
        console.error('Error pausing execution:', error);
        setError('Failed to pause the execution.');
      });
  };

  const handleResumeExecution = () => {
    axios.post(`${REACT_APP_BACKEND_URL}/api/tasks/resume/${executionId}`)
      .then(() => {
        setIsPaused(false);
        setIsStarted(true);
        fetchExecutionDetails(executionId);
      })
      .catch((error) => {
        console.error('Error resuming execution:', error);
        setError('Failed to resume the execution.');
      });
  };

  const handleStopExecution = () => {
    axios.post(`${REACT_APP_BACKEND_URL}/api/tasks/stop/${executionId}`)
      .then(() => {
        setIsPaused(false);
        setIsStarted(false);
        fetchExecutionDetails(executionId);
      })
      .catch((error) => {
        console.error('Error stopping execution:', error);
        setError('Failed to stop the execution.');
      });
  };

  const handleLogClick = (task) => {
    axios.get(`${REACT_APP_BACKEND_URL}/api/logs/${executionId}/task/${task._id}`)
      .then((response) => {
        setSelectedLog({ task, log: response.data.log, artifacts: response.data.artifacts });
      })
      .catch((error) => {
        console.error('Error fetching log:', error);
        setError('Unable to fetch logs. Please try again later.');
      });
  };

  const calculateDuration = (task) => {
    const runHistory = task.runHistory[task.runHistory.length - 1];
    const startedAt = runHistory?.startedAt ? new Date(runHistory.startedAt) : null;
    const completedAt = runHistory?.completedAt ? new Date(runHistory.completedAt) : null;
    if (startedAt && completedAt) {
      const duration = Math.abs(completedAt - startedAt);
      return `${Math.floor(duration / (1000 * 60 * 60))}h ${Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60))}m ${Math.floor((duration % (1000 * 60)) / 1000)}s`;
    }
    return 'In Progress';
  };

  const getRunHistoryForExecution = (task) => {
    return task.runHistory.find(history => history.executionId === executionId);
  };

  return (
    <div className="execution-details-container">
      <h2>Execution Details</h2>
      {error && <p className="error-message">{error}</p>}
      <div className="taskflow-controls">
        <button onClick={handlePauseExecution} disabled={!isStarted || isPaused} className="pause-button">Pause</button>
        <button onClick={handleResumeExecution} disabled={!isPaused} className="resume-button">Resume</button>
        <button onClick={handleStopExecution} disabled={!isStarted} className="stop-button">Stop</button>
      </div>
      {execution && (
        <div className="execution-info">
          <h3>Execution ID: {executionId}</h3>
          <p>Status: {execution.status}</p>
          <p>Started At: {new Date(execution.startedAt).toLocaleString()}</p>
          <p>Completed At: {execution.completedAt ? new Date(execution.completedAt).toLocaleString() : 'In Progress'}</p>
          <div className="execution-logs">
            <h4>Logs</h4>
            <pre>{execution.logs.join('\n')}</pre>
          </div>
          <div className="execution-artifacts">
            <h4>Artifacts</h4>
            {execution.artifacts && execution.artifacts.length > 0 ? (
              <ul>
                {execution.artifacts.map((artifact, index) => (
                  <li key={index}><a href={`/artifacts/${artifact}`} target="_blank" rel="noopener noreferrer">View Artifact</a></li>
                ))}
              </ul>
            ) : (
              <p>No artifacts available.</p>
            )}
          </div>
        </div>
      )}
      <div className="task-details">
        <h3>Tasks</h3>
        <table>
          <thead>
            <tr>
              <th>Task Title</th>
              <th>Status</th>
              <th>Started At</th>
              <th>Completed At</th>
              <th>Duration</th>
              <th>Logs</th>
              <th>Artifacts</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task, index) => {
              const runHistory = getRunHistoryForExecution(task);
              return runHistory ? (
                <tr key={index}>
                  <td>{task.title}</td>
                  <td>{runHistory.status}</td> {/* Updated to use status from runHistory */}
                  <td>{runHistory.startedAt ? new Date(runHistory.startedAt).toLocaleString() : 'N/A'}</td>
                  <td>{runHistory.completedAt ? new Date(runHistory.completedAt).toLocaleString() : 'In Progress'}</td>
                  <td>{calculateDuration(task)}</td>
                  <td>
                    <button onClick={() => handleLogClick(task)}>View Log</button>
                  </td>
                  <td>
                    {runHistory.artifacts && runHistory.artifacts.length > 0 && (
                      <ul>
                        {runHistory.artifacts.map((artifact, i) => (
                          <li key={i}><a href={`/artifacts/${artifact}`} target="_blank" rel="noopener noreferrer">View Artifact</a></li>
                        ))}
                      </ul>
                    )}
                  </td>
                </tr>
              ) : (
                <tr key={index}>
                  <td>{task.title}</td>
                  <td>No matching runHistory for this execution</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td><button disabled>View Log</button></td>
                  <td>No Artifacts</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {selectedLog && (
          <div className="log-display">
            <h3>Log for Task: {selectedLog.task.title}</h3>
            <pre>{selectedLog.log}</pre>
            <div className="artifacts-list">
              <h4>Artifacts:</h4>
              <ul>
                {selectedLog.artifacts.map((artifact, index) => (
                  <li key={index}>
                    <a href={artifact.path} target="_blank" rel="noopener noreferrer">
                      {artifact.file}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExecutionDetails;
