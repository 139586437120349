import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaPlus, FaShapes, FaUsers, FaUser } from 'react-icons/fa';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { useFlowRefresh } from '../context/FlowRefreshContext';
import '../styles/Sidebar.css';
import logo from '../images/flow.png';
import { FaBars, FaTimes } from 'react-icons/fa'

const Sidebar = () => {
  const { user, logout } = useContext(AuthContext);
  const { refreshFlows } = useFlowRefresh();
  const [flows, setFlows] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [subscriptionLevel, setSubscriptionLevel] = useState('');
  const REACT_APP_BACKEND_URL = 
    process.env.REACT_APP_BACKEND_URL || 
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_BACKEND_URL) || 
    'http://localhost:5000';
  const REACT_APP_FRONTEND_DOMAIN =
    process.env.REACT_APP_FRONTEND_DOMAIN ||
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_FRONTEND_DOMAIN) ||
    'http://localhost:3000';
  const STRIPE_CUSTOMER_PORTAL = window.RUNTIME_CONFIG.REACT_APP_STRIPE_CUSTOMER_PORTAL || '/manage-subscription';
  // const navigate = useNavigate();
  useEffect(() => {
    const fetchFlows = async () => {
      const token = localStorage.getItem('jwtToken');
      if (token) {
        try {
          const response = await axios.get(`${REACT_APP_BACKEND_URL}/api/flows`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.data && response.data.length > 0) {
            setFlows(response.data);
          }
        } catch (error) {
          console.error('Error fetching flows:', error);
        }
      }
    };
    fetchFlows();
  }, [refreshFlows, REACT_APP_BACKEND_URL]);

  useEffect(() => {
    const fetchSubscriptionLevel = async () => {
      if (user) {
        try {
          const response = await axios.get(`${REACT_APP_BACKEND_URL}/api/user/getsubscription`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            },
          });
          setSubscriptionLevel(response.data.billing.plan);
        } catch (error) {
          console.error('Error fetching subscription level:', error);
        }
      }
    };

    fetchSubscriptionLevel();
  }, [user, REACT_APP_BACKEND_URL]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  const handleClickOutsideSidebar = (event) => {
    if (isSidebarOpen && !event.target.closest('.sidebar') && !event.target.closest('.hamburger-menu')) {
      setIsSidebarOpen(false);
    }
  };

  const handleClickOutsideProfileMenu = (event) => {
    if (isProfileMenuOpen && !event.target.closest('.user-menu') && !event.target.closest('.user-avatar')) {
      setIsProfileMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener('click', handleClickOutsideSidebar);
    } else {
      document.removeEventListener('click', handleClickOutsideSidebar);
    }
    return () => {
      document.removeEventListener('click', handleClickOutsideSidebar);
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    if (isProfileMenuOpen) {
      document.addEventListener('click', handleClickOutsideProfileMenu);
    } else {
      document.removeEventListener('click', handleClickOutsideProfileMenu);
    }
    return () => {
      document.removeEventListener('click', handleClickOutsideProfileMenu);
    };
  }, [isProfileMenuOpen]);

  const handleSubscriptionClick = () => {
    // console.log(user);
    if (user?.stripeCustomerId) {
      console.log("Has stripeCustomerId");
      // Redirect to Stripe customer portal if the user has a stripeCustomerId
      window.location.href = STRIPE_CUSTOMER_PORTAL;
    } else {
      console.log("Doesn't have stripeCustomerId");
      // Redirect to the upgrade-subscription route
      // navigate('/upgrade-subscription');
      window.location.href = `${REACT_APP_FRONTEND_DOMAIN}/upgrade-subscription`;
    }
  };

  let upgradeMessage = '';
  switch (subscriptionLevel) {
    case 'Basic':
      upgradeMessage = 'Upgrade to Individual for more resources';
      break;
    case 'Individual':
      upgradeMessage = 'Upgrade to Team for collaboration features';
      break;
    case 'Team':
      upgradeMessage = 'Upgrade to Enterprise for maximum capacity';
      break;
    default:
      upgradeMessage = 'Explore our subscription plans';
  }

  return (
    <>
      <button className="hamburger-menu" onClick={toggleSidebar}>
        <FaBars />
      </button>
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <button className="close-sidebar" onClick={toggleSidebar}>
          <FaTimes />
        </button>
        <h2>
          <Link to="/" className="home-link">
            <img src={logo} alt="Flow AI Logo" className="logo-image" />
          </Link>
        </h2>
        <ul>
          <li>
            <Link to="/create-flow"
                onClick={() => {
                  if (window.innerWidth <= 768) {
                    setIsSidebarOpen(false);
                  }
                }}
              >
              <FaPlus className="sidebar-icon" /> Create Flow
            </Link>
          </li>
          <li>
            <Link to="/manage-models"
                onClick={() => {
                  if (window.innerWidth <= 768) {
                    setIsSidebarOpen(false);
                  }
                }}
              >
              <FaShapes className="sidebar-icon" /> Models
            </Link>
          </li>
          <li>
            <Link to="/manage-personas"
                onClick={() => {
                  if (window.innerWidth <= 768) {
                    setIsSidebarOpen(false);
                  }
                }}
              >
              <FaUsers className="sidebar-icon" /> Personas
            </Link>
          </li>
          <hr />
          <h3>Your Flows</h3>
          {flows.length > 0 ? (
            flows.map((flow) => (
              <li key={flow._id}>
                <Link to={`/task-flow/${flow._id}`} className="flow-link"
                  onClick={() => {
                    if (window.innerWidth <= 768) {
                      setIsSidebarOpen(false);
                    }
                  }}
                >
                  {flow.name}
                </Link>
              </li>
            ))
          ) : (
            <li>No flows available</li>
          )}
        </ul>

        {/* Display upgrade recommendation */}
        {user && (
          <div className="upgrade-message" onClick={handleSubscriptionClick}>
            <span className="upgrade-link">
              {upgradeMessage}
            </span>
          </div>
        )}

        {user && (
          <>
            <div className="user-profile-container">
              <div className="user-avatar" onClick={toggleProfileMenu}>
                {user.imageUrl ? (
                  <img src={user.imageUrl} alt="User avatar" className="avatar-img" />
                ) : (
                  <FaUser className="avatar-icon" />
                )}
              </div>
              {isProfileMenuOpen && (
                <div className="user-menu">
                  <ul>
                    <li><Link to="/user-profile"
                      onClick={() => {
                        if (window.innerWidth <= 768) {
                          setIsSidebarOpen(false);
                        }
                      }}
                    >My Profile</Link></li>
                    <li onClick={logout}>Log Out</li>
                  </ul>
                </div>
              )}
            </div>
            <div className="subscription-level">
              <button onClick={handleSubscriptionClick} className="subscription-icon-button">
                <FaUser /> {subscriptionLevel}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Sidebar;
