import React, { useContext, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const ManageSubscription = () => {
  const { user } = useContext(AuthContext);
  // const navigate = useNavigate();
  const STRIPE_CUSTOMER_PORTAL = 
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL || 
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_STRIPE_CUSTOMER_PORTAL) || 
    'default_portal_url';
  const REACT_APP_FRONTEND_DOMAIN =
    process.env.REACT_APP_FRONTEND_DOMAIN ||
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_FRONTEND_DOMAIN) ||
    'http://localhost:3000';

  useEffect(() => {
    console.log(user);
    if (user?.stripeCustomerId) {
      console.log("Has stripeCustomerId");
      // Redirect to the Stripe customer portal if the user has a stripeCustomerId
      window.location.href = STRIPE_CUSTOMER_PORTAL;
    } else {
      console.log("Doesn't have stripeCustomerId");
      // If no stripeCustomerId, redirect to the internal upgrade subscription page
      // navigate('/upgrade-subscription');
      window.location.href = `${REACT_APP_FRONTEND_DOMAIN}/upgrade-subscription`;
    }
  }, [user]);

  return <div>Loading your subscription details...</div>;
};

export default ManageSubscription;
