// src/utils/logger.js

class Logger {
    constructor() {
      this.logs = [];
    }
  
    log(message, level = 'info') {
      const timestamp = new Date().toISOString();
      const logEntry = { timestamp, level, message };
      this.logs.push(logEntry);
  
      // Optional: send logs to an external server
      // this.sendToServer(logEntry);
    }
  
    sendToServer(logEntry) {
      fetch(process.env.REACT_APP_LOGGING_URL || '/api/logs', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(logEntry),
      }).catch((err) => console.error('Error sending log to server:', err));
    }
  
    getLogs() {
      return this.logs;
    }
  }
  
  const logger = new Logger();
  
  export default logger;
  