import React, { useState, useEffect, useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
// import '../styles/Subscription.css';

function UpgradeSubscription() {
  const [message, setMessage] = useState('');
  const { user, loading } = useContext(AuthContext); // Access user and loading state from AuthContext
  const REACT_APP_FRONTEND_DOMAIN =
    process.env.REACT_APP_FRONTEND_DOMAIN ||
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_FRONTEND_DOMAIN) ||
    'http://localhost:3000';
  // const navigate = useNavigate();

  // Handle post-upgrade action, if needed
  const handleUpgradeComplete = () => {
    setMessage('Subscription upgraded successfully!');
    window.location.href = `${REACT_APP_FRONTEND_DOMAIN}/dashboard`;
    // navigate('/dashboard');
  };

  // Define constants for Stripe configuration
  const STRIPE_PRICING_TABLE_ID = 
  process.env.REACT_APP_STRIPE_PRICING_TABLE_ID ||
  (typeof window !== "undefined" && window.RUNTIME_CONFIG?.REACT_APP_STRIPE_PRICING_TABLE_ID) ||
  'default_pricing_table_id';

  const STRIPE_PUBLISHABLE_KEY = 
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
  (typeof window !== "undefined" && window.RUNTIME_CONFIG?.REACT_APP_STRIPE_PUBLISHABLE_KEY) ||
  'default_publishable_key';

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');  // Get the JWT token from localStorage
    if (token) {
      // Set token in Axios default headers for all future requests
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, []);


  useEffect(() => {
    if (!loading && user) {
    //   console.log(user); // Now you should have user data when loading is false
    //   console.log(
    //     process.env.REACT_APP_STRIPE_PRICING_TABLE_ID || 
    //     (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_STRIPE_PRICING_TABLE_ID) || 
    //     'pricing_table_id not defined'
    // );
    
    // console.log(
    //     process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || 
    //     (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_STRIPE_PUBLISHABLE_KEY) || 
    //     'publishable_key not defined'
    // );

      // window.addEventListener('message', (event) => {
      //   if (event.origin === 'https://checkout.stripe.com' && event.data.event === 'checkout.session.completed') {
      //     // Make an API call to check the webhook's response
      //     fetch(`${window.RUNTIME_CONFIG.REACT_APP_BACKEND_URL}/api/subscription-status`, {
      //       method: 'GET', // Or POST depending on your implementation
      //     })
      //     .then(response => response.json())
      //     .then(data => {
      //       if (data.error) {
      //         alert(data.message); // Notify the user about the failure
      //         window.location.href = data.redirectUrl; // Redirect to dashboard
      //       } else {
      //         alert('Subscription completed successfully!');
      //         window.location.href = '/dashboard'; // Redirect to dashboard
      //       }
      //     })
      //     .catch(error => {
      //       console.error('Error checking subscription status:', error);
      //     });
      //   }
      // }); 

      // return () => {
      //   window.removeEventListener('message', (event) => {
      //     if (event.origin === 'https://checkout.stripe.com' && event.data.event === 'checkout.session.completed') {
      //       handleUpgradeComplete();
      //     } else {
      //       console.log("Something else happened (Remove)");
      //     }
      //   });
      // };
    }
  }, [loading, user]); // Re-run effect when loading or user changes

  if (loading) {
    return <div>Loading...</div>; // Show a loading state while checking auth
  }

  if (!user) {
    return <div>Please log in to upgrade your subscription.</div>;
  }

  return (
    <div className="upgrade-subscription-container">
      <h2>Upgrade Subscription</h2>
      {message && <p className="message">{message}</p>}
      {user._id && (
        <stripe-pricing-table
          pricing-table-id={STRIPE_PRICING_TABLE_ID}
          publishable-key={STRIPE_PUBLISHABLE_KEY}
          client-reference-id={user._id} // Pass user ID to the Stripe Pricing Table
        ></stripe-pricing-table>
      )}
      {user.id && (
        <stripe-pricing-table
          pricing-table-id={STRIPE_PRICING_TABLE_ID}
          publishable-key={STRIPE_PUBLISHABLE_KEY}
          client-reference-id={user.id} // Pass user ID to the Stripe Pricing Table
        ></stripe-pricing-table>
      )}
    </div>
  );
}

export default UpgradeSubscription;
