import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';  // Import AuthContext
import { FaGoogle } from 'react-icons/fa';
import '../styles/Login.css';
import logger from './utils/logger';

const AuthPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [isLogin, setIsLogin] = useState(true); // Toggle between login and register
  const [isReset, setIsReset] = useState(false); // Toggle between login and password reset
  const [message, setMessage] = useState('');
  // const navigate = useNavigate();
  const REACT_APP_BACKEND_URL = 
    process.env.REACT_APP_BACKEND_URL || 
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_BACKEND_URL) || 
    'http://localhost:5000';

  const REACT_APP_FRONTEND_DOMAIN =
    process.env.REACT_APP_FRONTEND_DOMAIN ||
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_FRONTEND_DOMAIN) ||
    'http://localhost:3000';

  const { login } = useContext(AuthContext);  // Get the login function from context

  const { name, email, password, confirmPassword } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  // useEffect to handle token extraction after redirect from Google OAuth
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    if (token) {
      // console.log("Token retrieved from URL:", token);
      localStorage.setItem('jwtToken', token);  // Store JWT token in localStorage
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      // Optionally fetch user details if needed, or just redirect
      // console.log(`${REACT_APP_FRONTEND_DOMAIN}/dashboard`);
      // window.location.href = `${REACT_APP_FRONTEND_DOMAIN}/dashboard`;
      // Wait for 5 seconds before navigating
      // setTimeout(() => {
      //   window.location.href = `${REACT_APP_FRONTEND_DOMAIN}/dashboard`;
      // }, 5000);
      window.location.href = `${REACT_APP_FRONTEND_DOMAIN}/dashboard`;
    } else {
      // console.log("No token");
    }
  }, []); 

  const testBackendConnection = async () => {
    try {
      // console.log(`The Backend URL is: ${REACT_APP_BACKEND_URL}`);
      // console.log(`${REACT_APP_BACKEND_URL}`);
      const response = await axios.get(`${REACT_APP_BACKEND_URL}/`);
      // console.log('Backend response:', response.data);
      alert('Backend connection successful: ' + response.data);
    } catch (error) {
      // console.error('Error connecting to backend:', error);
      alert('Error connecting to backend');
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setMessage(''); // Reset any previous error messages

    try {
      if (isReset) {
        // Handle forgot password/reset password flow
        await axios.post(`${REACT_APP_BACKEND_URL}/auth/forgot-password`, { email });
        setMessage('Password reset email sent!');
      } else if (isLogin) {
        // Attempt to log in the user
        const response = await axios.post(`${REACT_APP_BACKEND_URL}/auth/login`, { email, password });

        // Call the login function from context and pass user data
        if (response.status === 200) {
          login(response.data.user, response.data.token);  // Update the context with user data
          // console.log(`Go to ${REACT_APP_FRONTEND_DOMAIN}/dashboard`);
          // Wait for 5 seconds before navigating
          // setTimeout(() => {
          //   window.location.href = `${REACT_APP_FRONTEND_DOMAIN}/dashboard`;
          // }, 5000);
          window.location.href = `${REACT_APP_FRONTEND_DOMAIN}/dashboard`;
        }
      } else {
        // Validate password confirmation during registration
        if (password !== confirmPassword) {
          setMessage('Passwords do not match');
          return;
        }
        // Attempt to register the user
        const response = await axios.post(`${REACT_APP_BACKEND_URL}/auth/register`, { name, email, password }, { withCredentials: true });

        // Call the login function from context and pass user data after registration
        if (response.status === 200) {
          const { user, token } = response.data;

          // Store the token in localStorage
          // console.log('Wait 5 seconds for ELSE localStorage: ', token);
          // setTimeout(() => {
          //   localStorage.setItem('jwtToken', token);
          // }, 5000);
          localStorage.setItem('jwtToken', token);

          // Update the context with user data
          // console.log('Before login');
          login(user, token);
          // console.log('After login');
          // console.log(`Navigate to ${REACT_APP_FRONTEND_DOMAIN}/dashboard`);
          // Wait for 5 seconds before navigating
          // setTimeout(() => {
          //   window.location.href = `${REACT_APP_FRONTEND_DOMAIN}/dashboard`;
          // }, 5000);
          window.location.href = `${REACT_APP_FRONTEND_DOMAIN}/dashboard`;
        }
      }
    } catch (err) {
      setMessage(err.response?.data?.message || 'Error during authentication');
    }
  };

  const loginWithGoogle = () => {
    // console.log("Log in with Google");
    // Redirect to Google OAuth via the backend
    // setTimeout(() => {
    //   window.location.href = `${REACT_APP_BACKEND_URL}/auth/google`;
    // }, 5000);
    window.location.href = `${REACT_APP_BACKEND_URL}/auth/google`;
  };

  return (
    <div className="auth-container">
      {/* <div>
        <h1>Frontend Application</h1>
        <button onClick={testBackendConnection}>Test Backend Connection</button>
      </div> */}
      <h2 className="auth-header">
        {isReset ? 'Reset Password' : isLogin ? 'Login' : 'Sign Up'}
      </h2>

      {/* Display any error message */}
      {message && <p className="error-message">{message}</p>}

      <form className="auth-form" onSubmit={onSubmit}>
        {isReset ? (
          <div className="auth-form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={onChange}
              className="auth-input"
              required
            />
          </div>
        ) : (
          <>
            {!isLogin && (
              <div className="auth-form-group">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={onChange}
                  className="auth-input"
                  required={!isLogin}
                />
              </div>
            )}

            <div className="auth-form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={onChange}
                className="auth-input"
                required
              />
            </div>

            <div className="auth-form-group">
              <label>Password</label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={onChange}
                className="auth-input"
                required
              />
            </div>

            {!isLogin && (
              <div className="auth-form-group">
                <label>Confirm Password</label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={onChange}
                  className="auth-input"
                  required={!isLogin}
                />
              </div>
            )}
          </>
        )}

        <button type="submit" className="auth-button">
          {isReset ? 'Send Reset Email' : isLogin ? 'Login' : 'Sign Up'}
        </button>
      </form>

      {!isReset && (
        <>
          <button onClick={loginWithGoogle} className="auth-social-button google">
          <FaGoogle style={{ marginRight: '8px' }} />
          Continue with Google
          </button>
        </>
      )}

      <p className="auth-footer">
        {isReset ? (
          <span onClick={() => setIsReset(false)}>Back to Login</span>
        ) : (
          <>
            <span onClick={() => setIsReset(true)}>Forgot Password?</span>
            <br />
            {isLogin ? 'Need an account?' : 'Already have an account?'}
            <span onClick={() => setIsLogin(!isLogin)}>
              {isLogin ? 'Sign up' : 'Login'}
            </span>
          </>
        )}
      </p>
    </div>
  );
};

export default AuthPage;
