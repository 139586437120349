// src/context/FlowRefreshContext.js
import React, { createContext, useState, useContext } from 'react';

const FlowRefreshContext = createContext();

export const FlowRefreshProvider = ({ children }) => {
  const [refreshFlows, setRefreshFlows] = useState(false);

  const triggerFlowRefresh = () => {
    setRefreshFlows(prevState => !prevState);
  };

  return (
    <FlowRefreshContext.Provider value={{ refreshFlows, triggerFlowRefresh }}>
      {children}
    </FlowRefreshContext.Provider>
  );
};

// Custom hook for easy access to FlowRefreshContext
export const useFlowRefresh = () => {
  return useContext(FlowRefreshContext);
};
