import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/ManagePersonas.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { faUsers, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';


function ManagePersonas() {
  const REACT_APP_BACKEND_URL = 
    process.env.REACT_APP_BACKEND_URL || 
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_BACKEND_URL) || 
    'http://localhost:5000';
  const [personas, setPersonas] = useState([]);
  const [filteredPersonas, setFilteredPersonas] = useState([]);
  const [filter, setFilter] = useState('all'); // Filter state: 'all', 'private', or 'public'
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [editingPersona, setEditingPersona] = useState(null);

  useEffect(() => {
    fetchPersonas();
  }, []);

  useEffect(() => {
    applyFilter(); // Reapply filter whenever filter state or personas list changes
  }, [filter, personas]);  

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');  // Get the JWT token from localStorage
    if (token) {
      // Set token in Axios default headers for all future requests
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, []);

  const fetchPersonas = () => {
    axios.get(`${REACT_APP_BACKEND_URL}/api/personas`)
      .then((response) => {
        // console.log(response.data);
        setPersonas(response.data);
      })
      .catch((error) => {
        console.error('Error fetching personas:', error);
      });
  };

  const applyFilter = () => {
    // console.log("Applying filter:", filter);
    if (filter === 'all') {
      setFilteredPersonas(personas);
    } else {
      const filtered = personas.filter((persona) => persona.visibility === filter);
      // console.log("Filtered personas:", filtered);
      setFilteredPersonas(filtered);
    }
  };

  const handleFilterChange = (event) => {
    const newFilter = event.target.value; // Get the selected filter value
    setFilter(newFilter); // Update the filter state
    // console.log("Filter changed to:", newFilter);
  };
  
  
  const handleAddOrUpdatePersona = () => {
    if (!name || !title || !description) {
      console.error('All fields are required');
      return;
    }
    const personaData = { name, title, description };

    if (editingPersona) {
      axios.put(`${REACT_APP_BACKEND_URL}/api/personas/${editingPersona._id}`, personaData)
        .then(fetchPersonas)
        .catch((error) => console.error('Error updating persona:', error));
    } else {
      axios.post(`${REACT_APP_BACKEND_URL}/api/personas`, personaData)
        .then(fetchPersonas)
        .catch((error) => console.error('Error creating persona:', error));
    }
    resetForm();
  };

  const handleEditPersona = (persona) => {
    setName(persona.name);
    setTitle(persona.title);
    setDescription(persona.description);
    setEditingPersona(persona);
  };

  const handleDeletePersona = (persona) => {
    axios.delete(`${REACT_APP_BACKEND_URL}/api/personas/${persona._id}`)
      .then(() => {
        fetchPersonas();
      })
      .catch((error) => {
        console.error('Error deleting persona:', error);
      });
  };

  const resetForm = () => {
    setName('');
    setTitle('');
    setDescription('');
    setEditingPersona(null);
  };

  return (
    <div className="manage-personas-container">
      <h2>Manage Personas</h2>
      <div className="persona-form">
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter persona name"
        />
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter persona title"
        />
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter persona description"
        />
        <div className="form-buttons">
          <button onClick={handleAddOrUpdatePersona} className="btn-small">
            {editingPersona ? 'Update Persona' : 'Add Persona'}
          </button>
          <button onClick={resetForm} className="btn-small btn-clear">
            Clear
          </button>
        </div>
      </div>
      <div className="persona-filter">
        <label>Filter:</label>
        <select value={filter} onChange={handleFilterChange}>
          <option value="all">All</option>
          <option value="public">Public</option>
          <option value="private">Private</option>
        </select>
      </div>
      <div className="persona-list-container">
        <table className="persona-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Title</th>
              <th>Domain</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredPersonas.map((persona) => (
              <tr key={persona._id}>
                <td>
                  <FontAwesomeIcon
                    icon={icons[persona.icon] || faUsers}
                    className="persona-icon"
                  />{' '}
                  {persona.name}
                </td>
                <td>{persona.title || 'N/A'}</td>
                <td>{persona.domain || 'N/A'}</td>
                <td>
                  {persona.visibility === 'private' && (
                    <>
                      <button onClick={() => handleEditPersona(persona)} style={{ marginRight: '10px' }}>
                        <FontAwesomeIcon icon={faEdit} /> Edit
                      </button>
                      <button onClick={() => handleDeletePersona(persona)}>
                        <FontAwesomeIcon icon={faTrash} /> Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ManagePersonas;
