// src/components/CreateFlow.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useFlowRefresh } from '../context/FlowRefreshContext';
import '../styles/CreateFlow.css';

function CreateFlow() {
  const REACT_APP_BACKEND_URL = 
    process.env.REACT_APP_BACKEND_URL || 
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_BACKEND_URL) || 
    'http://localhost:5000';
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const { triggerFlowRefresh } = useFlowRefresh();

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, []);

  const handleCreateFlow = async () => {
    if (name.trim()) {
      const newFlow = { name, description };

      try {
        await axios.post(`${REACT_APP_BACKEND_URL}/api/flows`, newFlow);
        setName('');
        setDescription('');
        triggerFlowRefresh();  // Trigger Sidebar to refresh flows
      } catch (error) {
        console.error('Error creating flow:', error);
      }
    } else {
      console.error('Flow name is required');
    }
  };

  return (
    <div className="create-flow-container">
      <h2>Create a New Flow</h2>
      <div className="flowform">
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter flow name"
        />
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter flow description (optional)"
        />
        <button onClick={handleCreateFlow}>Create Flow</button>
      </div>
    </div>
  );  
}

export default CreateFlow;
