import React, { createContext, useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Create the AuthContext
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  // const navigate = useNavigate();

  // Backend and frontend domain variables
  const REACT_APP_BACKEND_URL =
    process.env.REACT_APP_BACKEND_URL ||
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_BACKEND_URL) ||
    'http://localhost:5000';

  const REACT_APP_FRONTEND_DOMAIN =
    process.env.REACT_APP_FRONTEND_DOMAIN ||
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_FRONTEND_DOMAIN) ||
    'http://localhost:3000';

  useEffect(() => {
    const token = localStorage.getItem('jwtToken'); // Get the JWT token from localStorage
    // console.log('Token in localStorage during initialization:', token);
    if (token) {
      // Set token in Axios default headers for all future requests
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization']; // Ensure no invalid token is set
    }
  }, []);

  useEffect(() => {
    // Check if the user is already logged in when the app mounts
    const checkAuthStatus = async () => {
      try {
        const token = localStorage.getItem('jwtToken'); // Retrieve token from localStorage
        if (!token) {
          setLoading(false); // No token, stop loading
          return;
        }

        const response = await axios.get(`${REACT_APP_BACKEND_URL}/auth/user`, {
          headers: {
            Authorization: `Bearer ${token}`, // Send the JWT token in the Authorization header
          }
        });

        if (response.data.user) {
          // console.log(response.data.user);
          setUser(response.data.user);
        } else {
          console.log('No user found');
        }
      } catch (err) {
        console.error('Not authenticated', err);
      } finally {
        setLoading(false); // Set loading to false once we have checked the auth status
      }
    };

    checkAuthStatus();
  }, []);

  // Login function to set user data and store the JWT token
  const login = (userData, token) => {
    // console.log("Login attempt");
    if (token) {
      // Store the token and user data (if provided)
      // console.log('Wait 5 seconds for localStorage in login: ', token);
      // setTimeout(() => {
      //   localStorage.setItem('jwtToken', token);
      // }, 5000);
      localStorage.setItem('jwtToken', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    if (userData) {
      localStorage.setItem('user', JSON.stringify(userData));
      setUser(userData);
    }
    // Redirect using REACT_APP_FRONTEND_DOMAIN
    // console.log(`${REACT_APP_FRONTEND_DOMAIN}`);
    window.location.href = `${REACT_APP_FRONTEND_DOMAIN}/dashboard`; // Ensures consistent domain usage
  };

  // Logout function to clear user data and token
  const logout = () => {
    setUser(null);
    localStorage.removeItem('jwtToken'); // Remove token from localStorage
    localStorage.removeItem('user'); // Remove user data from localStorage
    // Redirect using REACT_APP_FRONTEND_DOMAIN
    window.location.href = `${REACT_APP_FRONTEND_DOMAIN}/login`; // Ensures consistent domain usage
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
