import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { AuthContext } from '../context/AuthContext';
import '../styles/UserProfile.css';
// import { decode as jwt_decode } from 'jwt-decode';

const UserProfile = () => {
  const [user, setUser] = useState(null);
  // const { userContenxt } = useContext(AuthContext);
  const [usage, setUsage] = useState({});
  const [limits, setLimits] = useState({});
  const [billing, setBilling] = useState(null);
  const REACT_APP_BACKEND_URL = 
    process.env.REACT_APP_BACKEND_URL || 
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_BACKEND_URL) || 
    'http://localhost:5000';

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');  // Get the JWT token from localStorage
    if (token) {
      // if (token) {
      //   const decoded = jwt_decode(token);
      //   console.log('Decoded token payload:', decoded);
      // }
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, []);
  
  useEffect(() => {
    // const fetchProfile = async () => {
    //   try {
    //     // Fetch user profile data on component mount
    //     const response = await axios.get(`${REACT_APP_BACKEND_URL}/api/user/profile`);
    //     setUser(response.data.user);
    //     setUsage(response.data.usage);
    //     setBilling(response.data.billing);
    //   } catch(error) {
    //       console.error('Error fetching user profile:', error);
    //   };
    // };
    const fetchProfile = async () => {
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        console.error('No token available in localStorage');
        return;
      }
      try {
        const response = await axios.get(`${REACT_APP_BACKEND_URL}/api/user/profile`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUser(response.data.user);
        setUsage(response.data.usage);
        setLimits(response.data.limits);
        setBilling(response.data.billing);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };
    
    fetchProfile();
  }, []);

  return (
    <div className="profile-container">
      {user ? (
        <>
          <h2>User Profile</h2>
          <p><strong>Name:</strong> {user.name}</p>
          <p><strong>Email:</strong> {user.email}</p>
          <h3>Subscription & Usage</h3>
          <p><strong>Plan:</strong> {billing?.plan || 'Freemium'}</p>
          <p><strong>Saved Flows Limit:</strong> {usage.flows} / {limits.flows}</p>
          <p><strong>Flows Executions:</strong> {usage.flowExecutions} / {limits.flowExecutions}</p>
          <p><strong>Saved Task Limit:</strong> {usage.tasks} / {limits.tasks}</p>
          <p><strong>Task Executions:</strong> {usage.taskUsage} / {limits.taskUsage}</p>
          <p><strong>Total Tokens Used:</strong> {usage.tokenUsage || 0} / {limits.tokenUsage}</p>
          <h3>Teams</h3>
          <ul>
            {user.teams.map((team) => (
              <li key={team._id}>{team.name}</li>
            ))}
          </ul>
        </>
      ) : (
        <p>Loading profile...</p>
      )}
    </div>
  );
};

export default UserProfile;
