import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactFlow, {
  Handle,
  MiniMap,
  Controls,
  Background,
  useEdgesState,
  useNodesState,
  addEdge,
} from 'react-flow-renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPauseCircle, faTimesCircle, faSpinner, faPencilAlt, faTrash, faExclamation } from '@fortawesome/free-solid-svg-icons'; 
import '../styles/TaskFlow.css';

// NodeRenderer component for custom nodes
const NodeRenderer = ({ data }) => {
  const [dependencies, setDependencies] = useState(data.task.dependencies || []);

  const removeDependency = (depId) => {
    setDependencies(dependencies.filter(d => d._id !== depId));
  };

  const handleDependencyChange = (e) => {
    const newDependency = e.target.value.trim();
    if (newDependency && !dependencies.some(dep => dep._id === newDependency)) {
      setDependencies([...dependencies, { _id: newDependency }]);
    }
  };

  const saveChanges = () => {
    const updatedTask = {
      ...data.task,
      dependencies
    };
    data.onEditTask(updatedTask);
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'success':
        return <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />;
      case 'paused':
        return <FontAwesomeIcon icon={faPauseCircle} style={{ color: 'orange' }} />;
      case 'stopped':
        return <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red' }} />;
      case 'in_progress':
        return <FontAwesomeIcon icon={faSpinner} spin style={{ color: 'blue' }} />;
      case 'failed':
        return <FontAwesomeIcon icon={faExclamation} style={{ color: 'red' }} />;
      default:
        return null;
    }
  };

  const handleEdit = () => {
    data.onEditTask(data.task);
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this task?')) {
      data.onDeleteTask(data.task._id);
    }
  };

  return (
    <div className="custom-node">
      <div className="node-header">
        <h4>{data.label}</h4>
        <div className="node-buttons">
          {/* <button onClick={saveChanges}>Save</button> */}
          <button onClick={handleEdit} className="edit-button"> <FontAwesomeIcon icon={faPencilAlt} /></button>
          <button onClick={handleDelete} className="delete-button"><FontAwesomeIcon icon={faTrash} /></button>
        </div>
      </div>
      <p>{data.task.description}</p>

      {/* <div className="dependencies">
        <h5>Dependencies</h5>
        <ul>
          {dependencies.map(dep => (
            <li key={dep._id}>
              {dep.title} <button onClick={() => removeDependency(dep._id)}>Remove</button>
            </li>
          ))}
        </ul>
        <input type="text" placeholder="Add dependency" onBlur={handleDependencyChange} />
      </div> */}

      <div className="status-icon">
        {getStatusIcon(data.task.status)}
      </div>

      <Handle type="source" position="right" id="a" style={{ background: '#555' }} />
      <Handle type="target" position="left" id="b" style={{ background: '#555' }} />
    </div>
  );
};

// Define nodeTypes outside the component
const nodeTypes = { customNode: NodeRenderer };

const TaskFlow = () => {
  const { id: flowId } = useParams();
  const navigate = useNavigate();
  const REACT_APP_BACKEND_URL = 
    process.env.REACT_APP_BACKEND_URL || 
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_BACKEND_URL) || 
    'http://localhost:5000';
  const REACT_APP_FRONTEND_DOMAIN =
    process.env.REACT_APP_FRONTEND_DOMAIN ||
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_FRONTEND_DOMAIN) ||
    'http://localhost:3000';
  const [tasks, setTasks] = useState([]);
  const [taskTitle, setTaskTitle] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [models, setModels] = useState([]);
  const [personas, setPersonas] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedPersona, setSelectedPersona] = useState('');
  const [taskType, setTaskType] = useState('automated');
  const [dependencies, setDependencies] = useState([]);
  const [flow, setFlow] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [taskHistory, setTaskHistory] = useState([]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [executionId, setExecutionId] = useState(null);
  const [executionHistory, setExecutionHistory] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [historyHeight, setHistoryHeight] = useState(200);
  const [isResizing, setIsResizing] = useState(false);

  const handleMouseDown = () => setIsResizing(true);
  const handleMouseMove = (e) => {
    if (isResizing) {
      const newHeight = window.innerHeight - e.clientY;
      if (newHeight > 100 && newHeight < 600) setHistoryHeight(newHeight);
    }
  };
  const handleMouseUp = () => setIsResizing(false);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');  // Get the JWT token from localStorage
    if (token) {
      // Set token in Axios default headers for all future requests
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, []);

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);

  useEffect(() => {
    if (flowId) {
      fetchFlow(flowId);
      fetchModelsAndPersonas();
      fetchMostRecentExecution();
    }
  }, [flowId]);

  useEffect(() => {
    if (isStarted || isPaused) {
      const interval = setInterval(() => {
        fetchTasks(flowId);
        fetchExecutionHistory();
      }, 1000);  // Reduced interval for more frequent updates
      setIntervalId(interval);
      return () => clearInterval(interval);
    }
  }, [isStarted, isPaused]);

  const fetchFlow = (id) => {
    // setIsStarted(false);
    // setIsPaused(false);
    axios.get(`${REACT_APP_BACKEND_URL}/api/flows/${id}`)
      .then((response) => {
        const flowData = response.data;
        setFlow(flowData);

        // Update the UI state based on flow status
        // setIsStarted(flowData.status === 'in_progress');
        // setIsPaused(flowData.status === 'paused');

        fetchTasks(flowData._id);
      })
      .catch((error) => {
        console.error('Error fetching flow:', error);
      });
  };

  // const handleStopAllExecutions = async () => {
  //   try {
  //     const response = await axios.post(`${window.RUNTIME_CONFIG.REACT_APP_BACKEND_URL}/api/executions/stopAll`);
  //     console.log('All executions stopped:', response.data);
  //     // Optionally, you can refresh the UI or show a notification
  //   } catch (error) {
  //     console.error('Error stopping all executions:', error);
  //   }
  // };

  const fetchMostRecentExecution = () => {
    axios.get(`${REACT_APP_BACKEND_URL}/api/executions/${flowId}/mostRecent`)
      .then((response) => {
        if (response.data) {
          const recentExecution = response.data;
          setExecutionId(recentExecution._id);
          setIsStarted(recentExecution.status === 'in_progress');
          setIsPaused(recentExecution.status === 'paused');
        } else {
          setExecutionId(null);
          setIsStarted(false);
          setIsPaused(false);
        }
      })
      .catch((error) => {
        console.error('Error fetching most recent execution:', error);
        setExecutionId(null);
        setIsStarted(false);
        setIsPaused(false);
      });
  };  

  const fetchModelsAndPersonas = () => {
    axios.get(`${REACT_APP_BACKEND_URL}/api/models`)
      .then((response) => {
        setModels(response.data);
      })
      .catch((error) => {
        console.error('Error fetching models:', error);
      });

    axios.get(`${REACT_APP_BACKEND_URL}/api/personas`)
      .then((response) => {
        setPersonas(response.data);
      })
      .catch((error) => {
        console.error('Error fetching personas:', error);
      });
  };

  const fetchExecutionHistory = () => {
    // setIsStarted(false);
    // setIsPaused(false);
  
    axios.get(`${REACT_APP_BACKEND_URL}/api/executions/${flowId}`)
      .then((response) => {
        const executions = response.data || []; // Fallback to an empty array if no executions exist
        setExecutionHistory(executions);
  
        // Check if there is an executionId and the execution history is not empty
        if (executionId && executions.length > 0) {
          const currentExecution = executions.find(exec => exec._id === executionId);
  
          // Check if the current execution exists and has the status 'failed'
          if (currentExecution && currentExecution.status === 'failed' && currentExecution.logs?.length > 0) {
            const lastLog = currentExecution.logs[currentExecution.logs.length - 1]; // Get the last log entry
            alert(`Execution failed: ${lastLog}`);
            setErrorMessage(`Execution failed: ${lastLog}`);
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching execution history:', error);
      });
  };  

  useEffect(() => {
    fetchExecutionHistory();
  }, []);

  const fetchTasks = (flowId, checkCompletion = false) => {
    axios.get(`${REACT_APP_BACKEND_URL}/api/tasks/${flowId}`)
      .then((response) => {
        const fetchedTasks = response.data;
        setTasks(fetchedTasks);
        checkFlowCompletion(fetchedTasks);
        setNodes(
          fetchedTasks.map((task) => ({
            id: task._id,
            data: {
              label: task.title,
              task,
              onEditTask: handleEditTask,
              onDeleteTask: handleDeleteTask,
            },
            position: { x: task.position.x, y: task.position.y },
            type: 'customNode',
            style: { border: task.status === 'success' ? '2px solid green' : '1px solid black' },
          }))
        );
        // console.log(fetchedTasks);
        setEdges(
          fetchedTasks.flatMap((task) =>
            task.dependencies.map((dep) => ({
              id: `e${dep._id}-${task._id}`,
              source: dep._id,
              target: task._id,
              type: 'default', 
              animated: true,
              style: { stroke: '#000', strokeWidth: 2 },
            }))
          )
        );
        // if (checkCompletion) {
        //   checkFlowCompletion(fetchedTasks);
        // }
      })
      .catch((error) => {
        console.error('Error fetching tasks:', error);
      });
  };

  const checkFlowCompletion = (tasks) => {
    const allCompleted = tasks.every(task => task.status === 'success');
    if (allCompleted) {
      setIsStarted(false);
      setIsPaused(false);
      fetchExecutionHistory();
    }
  };

  const handleEditTask = (task) => {
    console.log('Editing task:', task);

    setIsEditing(true);
    setEditingTaskId(task._id);
    setTaskTitle(task.title);
    setTaskDescription(task.description);
    // setSelectedModel(task.model ? task.model._id : '');
    // setSelectedPersona(task.persona ? task.persona._id : '');
    setSelectedModel(task.model._id || ''); // If task.model is an ID
    setSelectedPersona(task.persona._id || ''); // If task.persona is an ID
    setDependencies(task.dependencies || []); // Set the existing dependencies in the state
    setIsFormVisible(true);
  };

  const handleDeleteTask = (id) => {
    axios.delete(`${REACT_APP_BACKEND_URL}/api/tasks/${id}`)
      .then(() => {
        setTasks(tasks.filter(task => task._id !== id));
        setNodes((nds) => nds.filter((node) => node.id !== id));
        setEdges((eds) => eds.filter((edge) => edge.source !== id && edge.target !== id));
      })
      .catch((error) => {
        console.error('Error deleting task:', error);
      });
  };

  const handleAddOrEditTask = () => {
    if (taskTitle.trim() && selectedModel && selectedPersona && flow) {
      const taskData = {
        title: taskTitle,
        description: taskDescription,
        model: selectedModel,
        persona: selectedPersona,
        flowId: flow._id || flowId,
        position: { x: 50, y: 50 },
        dependencies: Array.from(new Set(dependencies.map(dep => dep._id))), // Ensure no duplicate dependencies
      };
      // console.log(taskData);
  
      if (isEditing && editingTaskId) {
        axios.put(`${REACT_APP_BACKEND_URL}/api/tasks/${editingTaskId}`, taskData)
          .then((response) => {
            setTasks(tasks.map(task => task._id === editingTaskId ? response.data : task));
            updateNode(response.data);
            fetchTasks(flowId);  // Refresh tasks
            resetTaskForm();
          })
          .catch((error) => {
            console.error('Error editing task:', error);
          });
      } else {
        axios.post(`${REACT_APP_BACKEND_URL}/api/tasks`, taskData)
          .then((response) => {
            setTasks([...tasks, response.data]);
            addNode(response.data);
            fetchTasks(flowId);  // Refresh tasks
            resetTaskForm();
          })
          .catch((error) => {
            console.error('Error adding task:', error);
          });
      }
    } else {
      console.error('Model, persona, or flow not set, or task title is empty');
    }
  };

  const addNode = (task) => {
    setNodes((nds) => [
      ...nds,
      {
        id: task._id,
        data: {
          label: task.title,
          task,
          onEditTask: handleEditTask,
          onDeleteTask: handleDeleteTask,
        },
        position: { x: task.position.x, y: task.position.y },
        type: 'customNode',
        style: { border: '1px solid black' },
      },
    ]);
  };

  const updateNode = (task) => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === task._id
          ? {
              ...node,
              data: {
                label: task.title,
                task,
                onEditTask: handleEditTask,
                onDeleteTask: handleDeleteTask,
              },
              position: { x: task.position.x, y: task.position.y },
            }
          : node
      )
    );
  };

  const handleConnect = (params) => {
    setEdges((eds) => {
      const newEdges = addEdge(params, eds);

      const updatedTask = tasks.find(task => task._id === params.target);
      const sourceTask = params.source;

      if (updatedTask) {
        const updatedDependencies = [...(updatedTask.dependencies || []), { _id: sourceTask }];
        axios.put(`${REACT_APP_BACKEND_URL}/api/tasks/${params.target}`, {
          dependencies: updatedDependencies
        })
        .then(response => {
          setTasks(tasks.map(task =>
            task._id === params.target ? { ...task, dependencies: response.data.dependencies } : task
          ));
        })
        .catch(err => console.error('Error updating task dependencies:', err));
      }

      return newEdges;
    });
  };

  const handleDeleteEdge = (edge) => {
    setEdges((eds) => {
      const updatedEdges = eds.filter((e) => e.id !== edge.id);

      const targetTask = tasks.find(task => task._id === edge.target);
      const sourceTask = edge.source;

      if (targetTask) {
        const updatedDependencies = targetTask.dependencies.filter(dep => dep._id !== sourceTask);
        axios.put(`${REACT_APP_BACKEND_URL}/api/tasks/${edge.target}`, {
          dependencies: updatedDependencies
          })
        .then(response => {
          setTasks(tasks.map(task =>
            task._id === edge.target ? { ...task, dependencies: response.data.dependencies } : task
          ));
        })
        .catch(err => console.error('Error updating task dependencies:', err));
      }

      return updatedEdges;
    });
  };

  const resetTaskForm = () => {
    setTaskTitle('');
    setTaskDescription('');
    setSelectedModel('');
    setSelectedPersona('');
    setTaskType('automated');
    setDependencies([]);
    setIsEditing(false);
    setEditingTaskId(null);
    setIsFormVisible(false);
  };

  const updateTaskPosition = (taskId, x, y) => {
    axios.put(`${REACT_APP_BACKEND_URL}/api/tasks/${taskId}`, { position: { x, y } })
      .then((response) => {
        setTasks(tasks.map(task => task._id === taskId ? response.data : task));
        updateNode(response.data);
      })
      .catch((error) => {
        console.error('Error updating task position:', error);
      });
  };

  const handleModelChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === 'createNewModel') {
      // navigate('/manage-models');
      window.location.href = `${REACT_APP_FRONTEND_DOMAIN}/manage-models`;
    } else {
      setSelectedModel(selectedValue);
    }
  };

  const handlePersonaChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === 'createNewPersona') {
      window.location.href = `${REACT_APP_FRONTEND_DOMAIN}/manage-personas`;
    } else {
      setSelectedPersona(selectedValue);
    }
  };

  const handleTaskTypeChange = (e) => {
    setTaskType(e.target.value);
  };

  const handleStartFlow = () => {
    axios.post(`${REACT_APP_BACKEND_URL}/api/tasks/start/${flowId}`)
      .then((response) => {
        const { executionId } = response.data;
        console.log(`Flow started with execution id ${executionId}`);
        setExecutionId(executionId); 
        setIsStarted(true);
        setIsPaused(false);
        setIntervalId(setInterval(() => fetchTasks(flowId), 1000));
      })
      .catch((error) => {
        console.error('Error starting task flow:', error);
        alert(`Error starting flow: ${error.response?.data?.msg || error.message}`);
        setIsStarted(false);
        setIsPaused(false);
      });
  };

  const handlePauseFlow = () => {
    axios.post(`${REACT_APP_BACKEND_URL}/api/tasks/pause/${executionId}`)
      .then(() => {
        setIsPaused(true);
        // updateTaskStatuses('paused');
      })
      .catch((error) => {
        console.error('Error pausing task flow:', error);
        alert(`Error Pausing flow: ${error.response?.data?.msg || error.message}`);
        setIsStarted(false);
        setIsPaused(false);
      });
  };

  const handleResumeFlow = () => {
    axios.post(`${REACT_APP_BACKEND_URL}/api/tasks/resume/${executionId}`)
      .then(() => {
        setIsPaused(false);
      })
      .catch((error) => {
        console.error('Error resuming task flow:', error);
        alert(`Error Resuming flow: ${error.response?.data?.msg || error.message}`);
        setIsStarted(false);
        setIsPaused(false);
      });
  };

  const handleStopFlow = () => {
    console.log(`Stopping task flow with execution id ${executionId}`);
    axios.post(`${REACT_APP_BACKEND_URL}/api/tasks/stop/${executionId}`)
      .then(() => {
        setIsStarted(false);
        setIsPaused(false);
        // resetTaskStatuses('stopped');
      })
      .catch((error) => {
        console.error('Error stopping task flow:', error);
        alert(`Error Stopping flow: ${error.response?.data?.msg || error.message}`);
        setIsStarted(false);
        setIsPaused(false);
      });
  };

  const handleExecutionClick = (executionId) => {
    navigate(`/execution/${executionId}`);
    window.location.href = `${REACT_APP_FRONTEND_DOMAIN}/execution/${executionId}`;
  };

  // const fetchTaskHistory = (taskId) => {
  //   axios.get(`${REACT_APP_BACKEND_URL}/api/tasks/${taskId}/history`)
  //     .then((response) => {
  //       setTaskHistory(response.data);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching task history:', error);
  //     });
  // };

  return (
    <div className="taskflow-container">
       <div className="taskflow-main">
      <h2>{flow ? flow.name : 'Loading...'}</h2>

      <div className="taskflow-controls">
        <button onClick={handleStartFlow} disabled={isStarted} className="start-button">Start</button>
        <button onClick={handlePauseFlow} disabled={!isStarted || isPaused} className="pause-button">Pause</button>
        <button onClick={handleResumeFlow} disabled={!isPaused} className="resume-button">Resume</button>
        <button onClick={handleStopFlow} disabled={!isStarted} className="stop-button">Stop</button>
        {/* <button onClick={handleStopAllExecutions} className="stop-all-button">Stop All Executions</button> */}
      </div>

      {isFormVisible && (
        <div className="taskform">
          <input
            type="text"
            value={taskTitle}
            onChange={(e) => setTaskTitle(e.target.value)}
            placeholder="Enter task title"
          />
          <textarea
            value={taskDescription}
            onChange={(e) => setTaskDescription(e.target.value)}
            placeholder="Enter task description"
          />
          <div className="model-selection">
            <label>Select Model:</label>
            <select value={selectedModel} onChange={handleModelChange}>
              <option value="">-- Select Model --</option>
              {models.map(model => (
                <option key={model._id} value={model._id}>{model.name}</option>
              ))}
              <option value="createNewModel">Create New Model</option>
            </select>
          </div>

          <div className="persona-selection">
            <label>Select Persona:</label>
            <select value={selectedPersona} onChange={handlePersonaChange}>
              <option value="">-- Select Persona --</option>
              {personas.map(persona => (
                <option key={persona._id} value={persona._id}>{persona.name}</option>
              ))}
              <option value="createNewPersona">Create New Persona</option>
            </select>
          </div>

          <div className="task-type-selection">
            <label>Task Type:</label>
            <select value={taskType} onChange={handleTaskTypeChange}>
              <option value="automated">Automated</option>
              <option value="manual">Manual</option>
            </select>
          </div>

          {/* Dependencies Section */}
          <div className="dependencies">
            <h5>Dependencies</h5>
            <ul>
              {dependencies.map(dep => (
                <li key={dep._id}>
                  {dep.title} <button onClick={() => setDependencies(dependencies.filter(d => d._id !== dep._id))}>Remove</button>
                </li>
              ))}
            </ul>
            {/* <input
              type="text"
              placeholder="Add dependency"
              onBlur={(e) => {
                const newDependency = e.target.value.trim();
                if (newDependency && !dependencies.some(dep => dep._id === newDependency)) {
                  setDependencies([...dependencies, { _id: newDependency }]);
                }
              }}
            /> */}
          </div>

          <div className="taskform-buttons">
            <button onClick={handleAddOrEditTask}>
              {isEditing ? 'Update Task' : 'Add Task'}
            </button>
            <button onClick={resetTaskForm} className="cancel-button">Cancel</button>
          </div>
        </div>
      )}

      <div className="taskflow-main">
      <div className="task-canvas">
        
        <ReactFlow
          nodes={nodes}
          edges={edges}
          fitView
          fitViewOptions={{
            padding: 0.1, // Adjust padding as needed
          }}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={handleConnect}
          onEdgeDoubleClick={(event, edge) => handleDeleteEdge(edge)}
          onNodeDragStop={(event, node) => updateTaskPosition(node.id, node.position.x, node.position.y)}
          nodeTypes={nodeTypes}
          // panOnScroll
          // panOnDrag
          // minZoom={0.5}
          // maxZoom={2}
          // defaultPosition={[0, 0]} // Starting position of the flow view
          // zoomOnScroll={false} // Prevents zooming on scroll, if desired
          // zoomOnDoubleClick={false} // Prevents zooming on double click, if desired
        >
          <button onClick={() => setIsFormVisible(!isFormVisible)} className="add-task-button">
            + Task
          </button>
          <MiniMap nodeColor={() => 'blue'}/>
          <Controls />
          <Background />
        </ReactFlow>
      </div>

        <div className="resize-handle" onMouseDown={handleMouseDown} />
        <div className="history-section" style={{ height: `${historyHeight}px` }}>
        {/* <div className="history-section"> */}
          <h3>Execution History</h3>
          <table>
            <thead>
              <tr>
                <th>Execution ID</th>
                <th>Status</th>
                <th>Started At</th>
                <th>Completed At</th>
                <th>Logs</th>
              </tr>
            </thead>
            <tbody>
              {executionHistory && executionHistory.length > 0 ? (
                executionHistory.map((history, index) => (
                  <tr key={index} onClick={() => handleExecutionClick(history._id)} style={{ cursor: 'pointer' }}>
                    <td>{history._id}</td>
                    <td>{history.status}</td>
                    <td>{history.startedAt ? new Date(history.startedAt).toLocaleString() : 'N/A'}</td>
                    <td>{history.completedAt ? new Date(history.completedAt).toLocaleString() : 'In Progress'}</td>
                    <td>{history.logs && history.logs.length > 0 ? history.logs.join(', ') : 'No Logs'}</td>
                    {/* Optional handling for artifacts */}
                    {/* {history.artifacts && history.artifacts.length > 0 ? (
                      <td>
                        <ul>
                          {history.artifacts.map((artifact, i) => (
                            <li key={i}><a href={`/artifacts/${artifact}`} target="_blank" rel="noopener noreferrer">View Artifact</a></li>
                          ))}
                        </ul>
                      </td>
                    ) : (
                      <td>No Artifacts</td>
                    )} */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" style={{ textAlign: 'center' }}>No Execution History Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {taskHistory.length > 0 && (
          <div className="history-section">
            <h3>Task History</h3>
            <table>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Started At</th>
                  <th>Completed At</th>
                  <th>Log</th>
                  <th>Artifacts</th>
                </tr>
              </thead>
              <tbody>
                {taskHistory.map((history, index) => (
                  <tr key={index}>
                    <td>{history.status}</td>
                    <td>{new Date(history.startedAt).toLocaleString()}</td>
                    <td>{history.completedAt ? new Date(history.completedAt).toLocaleString() : 'In Progress'}</td>
                    <td>{history.log}</td>
                    <td>
                      {history.artifacts && history.artifacts.length > 0 && (
                        <ul>
                          {history.artifacts.map((artifact, i) => (
                            <li key={i}><a href={`/artifacts/${artifact}`} target="_blank" rel="noopener noreferrer">View Artifact</a></li>
                          ))}
                        </ul>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      </div>
    </div>
  );
}

export default TaskFlow;
