import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';  // Import AuthContext
import { useFlowRefresh } from '../context/FlowRefreshContext';
import { FaPlus } from 'react-icons/fa';
import '../styles/Dashboard.css';

function Dashboard() {
  const REACT_APP_BACKEND_URL = 
    process.env.REACT_APP_BACKEND_URL || 
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_BACKEND_URL) || 
    'http://localhost:5000';
  const { user } = useContext(AuthContext);  // Get user from context
  const [flows, setFlows] = useState([]);
  const [flowName, setFlowName] = useState('');
  const { triggerFlowRefresh } = useFlowRefresh();

  // console.log('Rendering Dashboard component');

  useEffect(() => {
    // console.log("Opened Dashboard");
    try {
      // console.log("Go get token");
      const token = localStorage.getItem('jwtToken'); // Get the JWT token from localStorage
      // console.log("The getItem token is:", token);
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      } else {
        console.warn('No token found in localStorage');
      }
    } catch (error) {
      console.error('Error accessing localStorage:', error);
    }
  }, []);

  useEffect(() => {
    // Fetch all flows when component mounts
    const fetchFlows = async () => {
      try {
        // const token = localStorage.getItem('jwtToken');  // Get the JWT token from localStorage
        const response = await axios.get(`${REACT_APP_BACKEND_URL}/api/flows`);
        setFlows(response.data);
      } catch (error) {
        console.error('Error fetching flows:', error);
      }
    };

    fetchFlows();
  }, []);

  const handleDeleteFlow = (id) => {
    if (window.confirm('Are you sure you want to delete this flow?')) {
      // const token = localStorage.getItem('jwtToken');
      axios.delete(`${REACT_APP_BACKEND_URL}/api/flows/${id}`)
      .then(() => {
        setFlows(flows.filter(flow => flow._id !== id));
        triggerFlowRefresh();
      })
      .catch((error) => {
        console.error('Error deleting flow:', error);
      });
    }
  };

  return (
    <div className="dashboard-container">
      <h2>Dashboard</h2>
      <div className="create-flow">
        <Link to="/create-flow">
          <FaPlus className="create-flow" /> Create Flow
        </Link>
      </div>
      <div className="flowlist">
        <h3>Your Flows</h3>
        <div className="flow-list">
          {flows.map((flow) => (
            <div key={flow._id} className="flow-item">
              <div className="flow-info">
                <Link to={`/task-flow/${flow._id}`} className="flow-name">{flow.name}</Link>
              </div>
              <button onClick={() => handleDeleteFlow(flow._id)} className="delete-flow-btn">Delete</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
