import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/ManageModels.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';

function ManageModels() {
  const REACT_APP_BACKEND_URL = 
    process.env.REACT_APP_BACKEND_URL || 
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_BACKEND_URL) || 
    'http://localhost:5000';
  const [models, setModels] = useState([]);
  const [name, setName] = useState('');
  const [model, setModel] = useState('');
  const [url, setUrl] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [temperature, setTemperature] = useState('');
  const [maxTokens, setMaxTokens] = useState('');
  const [editingModel, setEditingModel] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchModels();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');  // Get the JWT token from localStorage
    if (token) {
      // Set token in Axios default headers for all future requests
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, []);

  const fetchModels = () => {
    axios.get(`${REACT_APP_BACKEND_URL}/api/models`)
      .then((response) => {
        setModels(response.data);
      })
      .catch((error) => {
        console.error('Error fetching models:', error);
      });
  };

  const validateInputs = () => {
    const temperatureValue = parseFloat(temperature);
    const maxTokensValue = parseInt(maxTokens, 10);

    if (!name || !model || !url) {
      setError('Name, Model, and URL are required.');
      return false;
    }
    if (isNaN(temperatureValue) || temperatureValue < 0 || temperatureValue > 1) {
      setError('Temperature must be a number between 0 and 1.');
      return false;
    }
    if (isNaN(maxTokensValue) || maxTokensValue <= 0) {
      setError('Max Tokens must be a positive integer.');
      return false;
    }
    setError('');
    return true;
  };

  const handleAddOrUpdateModel = () => {
    if (!validateInputs()) {
      return;
    }

    const modelData = { 
      name, 
      model, 
      url, 
      temperature: parseFloat(temperature), 
      max_tokens: parseInt(maxTokens, 10) 
    };
  
    // Include apiKey only if the user has provided it during an update
    if (apiKey) {
      modelData.apiKey = apiKey;
    }
    if (editingModel) {
      // Update model
      axios.put(`${REACT_APP_BACKEND_URL}/api/models/${editingModel._id}`, modelData)
        .then(() => {
          fetchModels();
          resetForm();
        })
        .catch((error) => {
          console.error('Error updating model:', error);
        });
    } else {
      // Create new model
      axios.post(`${REACT_APP_BACKEND_URL}/api/models`, modelData)
        .then(() => {
          fetchModels();
          resetForm();
        })
        .catch((error) => {
          console.error('Error creating model:', error);
        });
    }
  };

  const handleEditModel = (model) => {
    if (model.isUserModel) {
      setName(model.name);
      setModel(model.model);
      setUrl(model.url);
      setTemperature(model.temperature.toString());
      setMaxTokens(model.max_tokens.toString());
      setEditingModel(model);
    } else {
      alert("You cannot edit this model because it is a shared system model.");
    }
  };

  const handleDeleteModel = (model) => {
    if (!model.isUserModel) {
      alert("You cannot delete this model because it is a shared system model.");
      return;
    }
    axios.delete(`${REACT_APP_BACKEND_URL}/api/models/${model._id}`)
      .then(() => {
        fetchModels();
      })
      .catch((error) => {
        console.error('Error deleting model:', error);
      });
  };

  const resetForm = () => {
    setName('');
    setModel('');
    setUrl('');
    setApiKey('');
    setTemperature('');
    setMaxTokens('');
    setEditingModel(null);
    setError('');
  };

  return (
    <div className="manage-models-container">
      <h2>Manage Models</h2>
      {error && <p className="error">{error}</p>}
      <div className="model-form">
        <label>
          Model Name 
          <FontAwesomeIcon icon={faInfoCircle} data-tooltip-id="name-tooltip" />
          <Tooltip id="name-tooltip" place="top" effect="solid">
            The name of the AI model you are using.
          </Tooltip>
        </label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter model name"
        />

        <label>
          System Model
          <FontAwesomeIcon icon={faInfoCircle} data-tooltip-id="model-tooltip" />
          <Tooltip id="model-tooltip" place="top" effect="solid">
            The system or architecture of the model, e.g., GPT-3.5-turbo.
          </Tooltip>
        </label>
        <input
          type="text"
          value={model}
          onChange={(e) => setModel(e.target.value)}
          placeholder="Enter system model"
        />

        <label>
          Model URL 
          <FontAwesomeIcon icon={faInfoCircle} data-tooltip-id="url-tooltip" />
          <Tooltip id="url-tooltip" place="top" effect="solid">
            The API endpoint for the model.
          </Tooltip>
        </label>
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter model URL"
        />

        <label>
          API Key 
          <FontAwesomeIcon icon={faInfoCircle} data-tooltip-id="apikey-tooltip" />
          <Tooltip id="apikey-tooltip" place="top" effect="solid">
            The API key required to access the model.
          </Tooltip>
        </label>
        <input
          type="text"
          value={editingModel ? '' : apiKey}  // Set as blank on edit
          onChange={(e) => setApiKey(e.target.value)}
          placeholder="Enter API key"
        />

        <label>
          Temperature 
          <FontAwesomeIcon icon={faInfoCircle} data-tooltip-id="temperature-tooltip" />
          <Tooltip id="temperature-tooltip" place="top" effect="solid">
            Controls the randomness of the model's output. A value between 0 and 1. 0 is more deterministic, 1 is more random.
          </Tooltip>
        </label>
        <input
          type="text"
          value={temperature}
          onChange={(e) => setTemperature(e.target.value)}
          placeholder="Enter temperature (0 to 1)"
        />

        <label>
          Max Tokens 
          <FontAwesomeIcon icon={faInfoCircle} data-tooltip-id="maxtokens-tooltip" />
          <Tooltip id="maxtokens-tooltip" place="top" effect="solid">
            The maximum number of tokens (words or word fragments) to generate.
          </Tooltip>
        </label>
        <input
          type="text"
          value={maxTokens}
          onChange={(e) => setMaxTokens(e.target.value)}
          placeholder="Enter max tokens"
        />

        <button onClick={handleAddOrUpdateModel}>
          {editingModel ? 'Update Model' : 'Add Model'}
        </button>
        <button onClick={resetForm}>Clear</button>
      </div>
      <div className="model-list">
        <h3>Existing Models</h3>
        <ul>
          {models.map((model) => (
            <li key={model._id}>
            {model.name} - {model.url}
            {model.isUserModel && (
              <>
                <button onClick={() => handleEditModel(model)}>Edit</button>
                <button onClick={() => handleDeleteModel(model)}>Delete</button>
              </>
            )}
          </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ManageModels;
