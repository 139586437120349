// src/App.js
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthContext, AuthProvider } from './context/AuthContext';
import { FlowRefreshProvider } from './context/FlowRefreshContext';  // Import the provider
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import TaskFlow from './components/TaskFlow';
import CreateFlow from './components/CreateFlow';
import ManageModels from './components/ManageModels';
import ManagePersonas from './components/ManagePersonas';
import ExecutionDetails from './components/ExecutionDetails';
import UserProfile from './components/UserProfile';
import AuthPage from './components/AuthPage';
import LogoutPage from './components/LogoutPage';
import ManageSubscription from './components/ManageSubscription';
import UpgradeSubscription from './components/UpgradeSubscription'
import './App.css';

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>; 
  }

  return user ? children : <Navigate to="/login" />;
};

function App() {
  return (
    <Router>
      <AuthProvider>
        <FlowRefreshProvider>
          <div className="app-container">
            <Sidebar />
            <div className="content">
              <Routes>
                <Route path="/login" element={<AuthPage />} />
                <Route path="/logout" element={<LogoutPage />} />
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/task-flow/:id"
                  element={
                    <ProtectedRoute>
                      <TaskFlow />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/create-flow"
                  element={
                    <ProtectedRoute>
                      <CreateFlow />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/execution/:executionId"
                  element={
                    <ProtectedRoute>
                      <ExecutionDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manage-models"
                  element={
                    <ProtectedRoute>
                      <ManageModels />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manage-personas"
                  element={
                    <ProtectedRoute>
                      <ManagePersonas />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-profile"
                  element={
                    <ProtectedRoute>
                      <UserProfile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manage-subscription"
                  element={
                    <ProtectedRoute>
                      <ManageSubscription />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/upgrade-subscription"
                  element={
                    <ProtectedRoute>
                      <UpgradeSubscription />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </div>
          </div>
        </FlowRefreshProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
