// src/components/LogoutPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

const LogoutPage = ({ setAuthenticated }) => {
  // const navigate = useNavigate();

  const REACT_APP_BACKEND_URL = 
    process.env.REACT_APP_BACKEND_URL || 
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_BACKEND_URL) || 
    'http://localhost:5000';

  const REACT_APP_FRONTEND_DOMAIN =
    process.env.REACT_APP_FRONTEND_DOMAIN ||
    (typeof window !== "undefined" && window.RUNTIME_CONFIG && window.RUNTIME_CONFIG.REACT_APP_FRONTEND_DOMAIN) ||
    'http://localhost:3000';

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');  // Get the JWT token from localStorage
    if (token) {
      // Set token in Axios default headers for all future requests
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, []);
  
  useEffect(() => {
    const logout = async () => {
      try {
        // Call server-side logout
        await axios.get(`${REACT_APP_BACKEND_URL}/auth/logout`, { withCredentials: true });

        // Clear client-side tokens and state
        localStorage.removeItem('authToken'); // If you store the token in localStorage
        sessionStorage.removeItem('authToken'); // If you store the token in sessionStorage
        document.cookie = 'authToken=; Max-Age=0; path=/;'; // Clear auth token from cookies

        // Reset any authentication-related state
        setAuthenticated(false); // Assuming you use this to manage login state

        // Redirect to login page after logout
        // navigate('/login');
        window.location.href = `${REACT_APP_FRONTEND_DOMAIN}/login`;
      } catch (err) {
        console.error('Error logging out:', err);

        // Even if error occurs, redirect to login and clear state
        localStorage.removeItem('authToken');
        sessionStorage.removeItem('authToken');
        document.cookie = 'authToken=; Max-Age=0; path=/;';
        setAuthenticated(false);
        window.location.href = `${REACT_APP_FRONTEND_DOMAIN}/login`;
        // navigate('/login');
      }
    };

    logout();
  }, [setAuthenticated]);

  return (
    <div className="auth-container">
      <h2 className="auth-header">Logging out...</h2>
    </div>
  );
};

export default LogoutPage;
